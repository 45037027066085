import axios, { AxiosError } from 'axios'
import { Either, left, right } from 'fp-ts/Either'

export interface FormData {
  name: string
  lastname: string
  email: string
  phone: string
  cap: string
  arrival: string
  departure: string
  adults: string
  children?: string
  ages?: string
  reference: string
  message?: string
  offer_title?: string
  meeting_room_title?: string
  room_title?: string
  channel?: string
  privacy_policy: boolean
}

export interface QuotationFormSenderBackendConfiguration {
  formURL: string
}

export type QuotationFormSenderBackend = (
  data: FormData,
) => Promise<Either<AxiosError, void>>

export const QuotationFormSenderBackend =
  ({
    formURL,
  }: QuotationFormSenderBackendConfiguration): QuotationFormSenderBackend =>
  async (data): Promise<Either<AxiosError, void>> => {
    try {
      await axios({
        method: 'POST',
        url: formURL,
        headers: {
          'Content-type': 'application/json',
        },
        data,
      })

      const response = await axios({
        method: 'POST',
        url: `https://bedirect.bookingexpert.it/beacr/ws/customerRequestApi?hotel=41478&layout=13589&widgetapikey=41478_622f8c6d744443eda278d499b2d0c64e&firstname=${
          data.name
        }&lastname=${data.lastname}&email=${data.email}&phone=${
          data.phone
        }&request=${`Adulti: ${data.adults}, Bambini: ${data.children} (${data.ages}) - ${data.message}`}&checkin=${data.arrival.replace(
          /\//g,
          '-',
        )}&checkout=${data.departure.replace(/\//g, '-')}&channel=${
          data.channel
        }`,
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
      })

      return right(undefined)
    } catch (error) {
      return left(error as any)
    }
  }
